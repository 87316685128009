<script lang="ts" setup>
import { useToast } from 'primevue/usetoast'
// import { baseConfig } from '~/lib/base.config';
// import { useStoreApi } from '~/store/api.store';

// import { useUserStore } from '../store/user.store'
// import { useAuth } from '#auth'

// import { appConfig } from '~/composables/app_setting'
// import { useThemeStore } from '~/stores'
definePageMeta({
	auth: false,
	// navigateAuthenticatedTo: '/',
	layout: false
})
// const themeStore = useThemeStore()
const { signIn } = useAuth()
const popup = useToast()
const loading = ref(false)

const infoApp = appConfig.infoapp
const username = ref('')
const password = ref('')
const checked = ref(false)
const route = useRouter()
const clickLogin = async () => {
	// console.log('clickLogin:', username.value, password.value)
	/* const resp = await useUser.loginDBFront(username, password);
  console.log('loginDBFront resp:', resp);

  //console.log('xgetLogintest:', xgetLogintest);
  return */
	if (username.value === '' || password.value === '') {
		// alert('email or password is empty')
		popup.add({
			severity: 'error',
			summary: 'Error Message',
			detail: 'email or password is empty',
			life: 3000
		})
		return
	}
	loading.value = true
	const sigin = await signIn('credentials', {
		email: username.value,
		password: password.value,
		redirect: false
	})

	console.log('sigin:', sigin)
	if (sigin.error) {
		popup.add({
			severity: 'error',
			summary: 'Error Message',
			detail: sigin.error,
			life: 3000
		})
		loading.value = false
	} else {
		popup.add({
			severity: 'success',
			summary: 'Success Message',
			detail: 'Login success',
			life: 3000
		})
		loading.value = false
		await navigateTo('/', { external: true })
	}

	/* if (sigin.status === 200) {
		await route.push('/')// ('/apiview/');
	} */

	// @ts-ignore
	/* const xgetLogintest = await useUser.getLogintest(username, password)
  console.log('xgetLogintest:', xgetLogintest) */
	// const {} = useAuth()
	/*  const checkLogin = useUser.getLogin(username, password);
  console.log('checkLogin:', checkLogin); */
	// await route.push('/apiview/');

	/*   $auth.loginWith('local', {
	  data: {
		email: email.value,
		password: password.value
	  }
	})
	  .then((res) => {
		console.log('loginWith res', res);
		$router.push('/ccnl/home');
	  })
	  .catch((err) => {
		console.log('loginWith err', err);
	  }); */
}

</script>

<template>
  <div>
    <Toast />
    <Link
      rel="stylesheet"
      :href="useThemeStore().link || 'https://cdn.jsdelivr.net/npm/primevue@3.15.0/resources/themes/vela-blue/theme.css'"
    />

    <div class="surface-0 flex align-items-center justify-content-center min-h-screen min-w-screen overflow-hidden">
      <div class="flex justify-content-center p-2 lg:p-0" style="min-width:80%">
        <!--  <div class="col-12 mt-5 xl:mt-0 text-center">
        <img :src="`/images/logo-${logoColor}.svg`" alt="Sakai logo" class="mb-5" style="width:81px; height:60px;">
      </div> -->
        <div
          class="col-12 xl:col-6"
          style="border-radius:56px; padding:0.3rem; background: linear-gradient(180deg, var(--primary-color), rgba(33, 150, 243, 0) 30%);"
        >
          <div
            class="h-full w-full m-0 p-y-19 p-x-4"
            style="border-radius:53px; background: linear-gradient(180deg, var(--surface-50) 38.9%, var(--surface-0));"
          >
            <div class="text-center mb-7">
              <img src="/images/login_icone/Logo_Redigo_LOGIN.png" alt="Image" height="50" class="mb-3">
              <div class="flex items-center justify-center mt3">
                <div class="text-900 text-4xl font-medium mb-3">
                  {{ infoApp.Title }}
                </div>
                <img
                  src="/images/login_icone/Coccarda_Redigo_LOGIN.png"
                  alt="Image"
                  height="50"
                  class="mb-3 ml-5"
                >
              </div>
              <!-- <div class="text-900 text-3xl font-medium mb-3">
                {{ infoApp.Title }}
              </div>
              <span class="text-5 font-medium">versione {{ `${infoApp.releaseType} ${infoApp.version} del
															${infoApp.datebuild}` }}</span> -->
            </div>
            <div v-if="loading === true" class="text-center">
              <div class="spinner-border text-primary" role="status">
                <!-- spinner all page: primevue sakaiprime -->
                <ProgressSpinner stroke-width="5" animation-duration=".5s" class="full-screen" />
              </div>
              <div class="text-900 text-xl font-medium mb-3">
                Loading...
              </div>
            </div>
            <div v-if="loading !== true" class="w-full md:w-10 mx-auto">
              <span class="p-float-label ">
                <input
                  id="username"
                  v-model="username"
                  type="text"
                  class="p-inputtext w-full  h-15 border-primary mt-3 border-round border-1 focus-within: border-primary"
                  :pt="{ root: { class: '' } }"
                  style="padding: 1rem;"
                >
                <label for="username" class="block text-900 text-xl font-medium">Email</label>
              </span>
              <span class="p-float-label mt-5">
                <input
                  id="password"
                  v-model="password"
                  type="password"
                  class="p-inputtext w-full  h-15 border-primary mt-3 border-round border-1 focus-within: border-primary "
                  :pt="{ root: { class: '' } }"
                  style="padding: 1rem;"
                >
                <!-- <Password
                  id="password"
                  v-model="password"

                  :toggle-mask="true"
                  class="w-full mt-3"
                  input-class="w-full border-primary"
                  :input-style="{ padding: '1rem' }"
                /> -->
                <label for="password" class="block text-900 font-medium text-xl ">Password</label>
              </span>
              <!-- <label for="password1" class="block text-900 font-medium text-xl mb-2">Password</label>
              <Password
                id="password1"
                v-model="password"
                placeholder="Password"
                :toggle-mask="true"
                class="w-full mb-3"
                input-class="w-full"
                :input-style="{ padding: '1rem' }"
              /> -->

              <div class="flex align-items-center justify-content-between m-y-5">
                <div class="flex align-items-center">
                  <Checkbox id="rememberme1" v-model="checked" :binary="true" class="mr-2" />
                  <label for="rememberme1">Ricordami</label>
                </div>
                <a
                  class="font-medium no-underline ml-2 text-right cursor-pointer"
                  style="color: var(--primary-color)"
                >Password dimenticata?</a>
              </div>
              <Button label="Accedi" class="w-full p-3 mt-3 text-xl" @click="clickLogin" />
              <!--Button logout
            <Button label="Logout" class="w-full p-3 text-xl" @click="clickLogout" />-->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- <div class="grid">
    <div class="col-12">
      <label for="username" class="block text-900 text-xl font-medium mb-2">Email</label>
      <InputText
        id="username"
        v-model="username"
        type="text"
        class="w-full mb-3"
        placeholder="Email"
        style="padding:1rem;"
      />

      <label for="password1" class="block text-900 font-medium text-xl mb-2">Password</label>
      <Password
        id="password1"
        v-model="password"
        placeholder="Password"
        :toggle-mask="true"
        class="w-full mb-3"
        input-class="w-full"
        :input-style="{ padding: '1rem' }"
      />
    </div>
    <div class="col-12">
      <div class="flex align-items-center justify-content-between mb-5">
        <div class="flex align-items-center">
          <Checkbox id="rememberme1" v-model="checked" :binary="true" class="mr-2" />
          <label for="rememberme1">Remember me</label>
        </div>
        <a class="font-medium no-underline ml-2 text-right cursor-pointer" style="color: var(--primary-color)">Forgot
          password?</a>
      </div>
      <Button label="Sign In" class="w-full p-3 text-xl" @click="clickLogin" />
    </div>
  </div> -->

  <!--  <Link rel="stylesheet" :href="'https://cdn.jsdelivr.net/npm/primevue@3.15.0/resources/themes/vela-blue/theme.css'" /> -->
</template>

<style>
/* .p-inputtext {
	background-color: var(--bg-primary) !important;
	color: var(--text-color) !important;
}
.p-filled {
	background-color: var(--bg-primary) !important;
	color: var(--text-color) !important;
} */
</style>
